import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 720.000000 619.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,619.000000) scale(0.100000,-0.100000)">
          <path d="M1450 6144 c-47 -23 -91 -43 -97 -43 -21 -1 -16 -41 5 -41 9 1 89 25
177 55 116 39 163 51 172 43 9 -7 13 -7 13 1 0 6 15 12 33 12 17 1 46 5 62 9
18 4 -34 8 -125 8 l-155 0 -85 -44z"/>
<path d="M4888 5983 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1291 5873 c-38 -124 -69 -239 -86 -326 -8 -37 -17 -72 -20 -77 -3
-6 0 -19 7 -29 13 -18 16 -17 65 32 89 91 126 147 134 202 11 76 10 195 -1
195 -5 0 -10 8 -11 18 -1 22 -31 74 -46 79 -6 2 -24 -38 -42 -94z"/>
<path d="M6700 5805 c0 -15 57 -66 85 -75 18 -6 41 -20 50 -30 22 -25 45 -26
45 -2 0 9 -19 28 -42 42 -22 14 -46 30 -52 35 -31 27 -86 46 -86 30z"/>
<path d="M6892 5690 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M6910 5670 c0 -14 -3 -36 -6 -50 -4 -18 -3 -21 4 -11 8 11 11 9 15
-10 4 -21 4 -20 6 6 1 23 3 27 11 15 8 -12 10 -11 9 5 -1 27 -17 74 -18 55 -1
-13 -2 -13 -11 0 -8 12 -10 10 -10 -10z"/>
<path d="M3041 5434 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3075 5400 c-3 -5 3 -10 15 -10 12 0 18 5 15 10 -3 6 -10 10 -15 10
-5 0 -12 -4 -15 -10z"/>
<path d="M4695 4850 c-4 -7 -2 -16 5 -20 14 -9 22 -128 9 -136 -5 -3 -10 10
-10 28 -1 26 -3 28 -6 9 -7 -37 6 -88 24 -95 14 -6 15 1 10 56 -3 38 -2 56 3
47 13 -20 0 58 -15 95 -8 19 -15 25 -20 16z"/>
<path d="M833 4710 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M4670 4704 c0 -16 5 -36 10 -44 6 -10 7 -2 3 25 -7 49 -13 58 -13 19z"/>
<path d="M911 4524 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6914 4158 c-3 -5 4 -23 15 -41 l21 -32 0 41 c0 30 -4 41 -15 41 -9
0 -18 -4 -21 -9z"/>
<path d="M2580 4041 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
